<template>
  <div v-loading="loading">
    <el-form
      :model="baseInfoForm"
      label-position="right"
      ref="baseInfoForm"
      label-width="120px"
      class="demo-ruleForm"
      :rules="telRules"
    >
      <!--修改手机号-->
      <div class="base-info-item">
        <h4>修改手机号（{{telStep === 1?'验证当前手机号':'绑定新手机号'}}）</h4>
        <div>
          <el-form-item prop="mobile" :label="telStep === 1?'当前手机号：':'绑定手机号：'">
            <el-input
              v-model="baseInfoForm.mobile"
              class="ipt-default width"
              placeholder="请输入手机号"
              :disabled="telStep === 1?true:false"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片验证码：" prop="captcha">
            <div style="height:40px;display:flex;width:400px;">
              <el-input
                v-model="baseInfoForm.captcha"
                class="ipt-default"
                placeholder="请输入图片验证码"
              ></el-input>
              <img :src="telImg" class="codeImg" @click="GET_captchas('telChange', 'VALIDATE_MOBILE')"/>
            </div>
          </el-form-item>
          <el-form-item label="短信验证码：" prop="sms_code">
            <div style="width:400px;display:flex;">
              <el-input v-model="baseInfoForm.sms_code" class="ipt-default width" placeholder="请输入短信验证码"></el-input>
              <el-button type="primary"  @click="getCode" style="margin-left:10px;">获取手机验证码</el-button>
            </div>
          </el-form-item>
          <el-button type="primary" style="margin:0 auto;display:block;" @click="save('tel')">{{telStep === 1?'提交验证':'提交绑定'}}</el-button>
        </div>
      </div>
    </el-form>

    <el-form
      :model="baseInfoForm2"
      status-icon
      label-position="right"
      ref="baseInfoForm2"
      label-width="120px"
      class="demo-ruleForm"
      :rules="passwordRules"
    >
      <!--修改密码-->
      <div class="base-info-item">
        <h4>修改密码</h4>
        <div>
          <el-form-item label="当前密码：" prop="passwordNow">
            <el-input
              v-model="baseInfoForm2.passwordNow"
              class="ipt-default width"
              placeholder="请输入当前密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="password">
            <el-input
              v-model="baseInfoForm2.password"
              class="ipt-default width"
              placeholder="请输入6~20位新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="passwords">
            <el-input
              v-model="baseInfoForm2.passwords"
              class="ipt-default width"
              placeholder="请输入6~20位新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片验证码：" prop="captcha">
            <div style="height:40px;display:flex;width:400px;">
              <el-input
                v-model="baseInfoForm2.captcha"
                class="ipt-default"
                placeholder="请输入图片验证码"
              ></el-input>
              <img :src="passwordImg" class="codeImg" @click="GET_captchas('passwordChange', 'MODIFY_PASSWORD')"/>
            </div>
          </el-form-item>
          <el-button
            type="primary"
            style="margin:0 auto;display:block;"
            @click="save('password')"
          >保存修改</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import * as API_shopAccountSafety from "@/api/shopAccountSafety";
import md5 from 'js-md5'

export default {
  data() {
    return {
      /**  加载中。。。 */
      loading: false,

      /**  修改手机号提交表单 */
      baseInfoForm: {
        mobile: "00000000000",
        uuid: "telChange",
        captcha: "",
        sms_code: "",
      },

      /**  修改手机号提交表单验证 */
      telRules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        sms_code: [
          { required: false, message: "请输入手机验证码", trigger: "blur" },
        ],
      },

      /**  修改手机号步骤 */
      telStep: 1,

      /**  是否获取了手机验证码 */
      isGetCode: false,

      /**  修改密码提交表单 */
      baseInfoForm2: {
        uuid: "passwordChange",
        captcha: "",
        passwordNow: "",
        password: "",
      },

      /** 显示图片验证码 */
      telImg: "",
      passwordImg: "",

      /**  修改密码提交表单验证 */
      passwordRules: {
        passwordNow: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "请输入6~20位新密码", trigger: "blur" },
        ],
        passwords: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "请输入6~20位新密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              value !== this.baseInfoForm2.password
                ? callback(new Error("两次输入密码不一致!"))
                : callback();
            },
            trigger: "blur",
          },
        ],
        captcha: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    // 获取图片验证码
    this.GET_captchas("telChange", "VALIDATE_MOBILE");
    this.GET_captchas("passwordChange", "MODIFY_PASSWORD");

    // 获取当前手机号
    this.GET_shopsSecurityGetMobile();
  },
  activated() {
    // 获取图片验证码
    this.GET_captchas("telChange", "VALIDATE_MOBILE");
    this.GET_captchas("passwordChange", "MODIFY_PASSWORD");

    // 获取当前手机号
    this.GET_shopsSecurityGetMobile();
  },
  methods: {
    /**  保存提交表单 */
    save(type) {
      if (type === "tel") {
        if (this.isGetCode === 1) {
          this.$message.error("请先获取手机验证码！");
          return false;
        }
        if (this.telStep === 1) {
          this.$refs.baseInfoForm.validate((valid) => {
            if (valid) {
              API_shopAccountSafety.shopsSecurityExchangeBind(this.baseInfoForm).then((res) => {
                this.$message.success("当前手机号验证成功！");
                this.telStep = 2;
                this.isGetCode = 1;
                this.baseInfoForm = { mobile: "", uuid: "telChange", captcha: "", sms_code: "" };
                this.GET_captchas("telChange");
              });
            } else {
              this.$message.error("请填写完整手机号换绑表单！");
              return false;
            }
          });
        } else if (this.telStep === 2) {
          this.$refs.baseInfoForm.validate((valid) => {
            if (valid) {
              API_shopAccountSafety.shopsSecurityExchangeBindNew(this.baseInfoForm).then((res) => {
                this.$message.success("手机号换绑成功！");
                this.baseInfoForm = { mobile: "", uuid: "telChange", captcha: "", sms_code: "" };
                this.telStep = 1;
                this.isGetCode = 1;
                this.telRules.mobile[0].required = true;
                this.telRules.captcha[0].required = true;
                this.telRules.sms_code[0].required = false;
                this.GET_shopsSecurityGetMobile();
                this.GET_captchas("telChange", "VALIDATE_MOBILE");
              });
            } else {
              this.$message.error("请填写完整手机号换绑表单！");
              return false;
            }
          });
        }
      } else if (type === "password") {
        this.$refs.baseInfoForm2.validate((valid) => {
          if (valid) {
            var json = {};
            json.password = md5(this.baseInfoForm2.passwordNow);
            API_shopAccountSafety.shopsSecurityJudgePassword(json).then((res) => {
              this.baseInfoForm2.password = md5(this.baseInfoForm2.password);
              API_shopAccountSafety.shopsSecurityPassword(this.baseInfoForm2).then((res) => {
                this.$message.success("修改密码成功！");
                this.baseInfoForm2 = {uuid: "passwordChange", captcha: "", passwordNow: "", password: "" };
                this.GET_captchas("passwordChange", "MODIFY_PASSWORD");
                console.log(res);
              });
            });
          } else {
            this.$message.error("请填写完整修改密码表单！");
            return false;
          }
        });
      }
    },

    /**  获取图片验证码 */
    GET_captchas(uuid, scene) {
      const t = new Date().getTime()
      console.log(uuid, scene)
      if (uuid === "passwordChange") {
        this.passwordImg = API_shopAccountSafety.captchas(uuid, scene) + '?t=' + t;
      } else if (uuid === "telChange") {
        this.isGetCode = 1;
        this.telRules.mobile[0].required = true;
        this.telRules.captcha[0].required = true;
        this.telRules.sms_code[0].required = false;
        if (this.telStep === 2) {
          scene = "BIND_MOBILE";
        }
        this.telImg = API_shopAccountSafety.captchas(uuid, scene) + '?t=' + t;
      }
    },

    /**  获取手机验证码 */
    getCode() {
      this.$refs.baseInfoForm.validate((valid) => {
        if (valid) {
          if (this.telStep === 1) {
            API_shopAccountSafety.shopsSecuritySend(this.baseInfoForm).then((res) => {
              this.$message.success("获取验证码成功！");
              this.telRules.mobile[0].required = false;
              this.telRules.captcha[0].required = false;
              this.telRules.sms_code[0].required = true;
              this.isGetCode = 2;
            });
          } else if (this.telStep === 2) {
            API_shopAccountSafety.shopsSecurityBindSend(this.baseInfoForm).then((res) => {
              this.$message.success("获取验证码成功！");
              this.telRules.mobile[0].required = true;
              this.telRules.captcha[0].required = false;
              this.telRules.sms_code[0].required = true;
              this.isGetCode = 2;
            });
          }
        } else {
          this.$message.error("请填写图片验证码！");
          return false;
        }
      });
    },

    /**  获取当前手机号 */
    GET_shopsSecurityGetMobile() {
      API_shopAccountSafety.shopsSecurityGetMobile().then((res) => {
        this.baseInfoForm.mobile = res;
      });
    },
  },
};
</script>

<style type='text/scss' lang='scss' scoped>
/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;
  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item > div {
  margin-left: 5%;
}
div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }
}

.codeImg{
  height:40px;
  margin-left:10px;
}

.width {
  width: 400px;
}
</style>
<style type='text/scss' lang='scss'>
.el-form-item__label {
  word-break: break-all;
}
</style>
