/**
 * 店铺安全API
 */

import request from '@/utils/request'
import { api } from '@/libs/common/domain'

/**
 * 获取图片验证码
 * @param uuid scene
 */
export function captchas(uuid, scene) {
  return `${api.base}/captchas/${uuid}/${scene}`
}

/**
 * 修改密码
 * @param params
 */
export function shopsSecurityPassword(params) {
  return request({
    url: '/seller/shops/security/password',
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 发送当前手机验证码
 * @param params
 */
export function shopsSecuritySend(params) {
  return request({
    url: '/seller/shops/security/send',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 当前手机验证码验证
 * @param params
 */
export function shopsSecurityExchangeBind(params) {
  return request({
    url: '/seller/shops/security/exchange-bind',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 发送新绑定手机验证码
 * @param params
 */
export function shopsSecurityBindSend(params) {
  return request({
    url: `/seller/shops/security/bind/send/${params.mobile}`,
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 新绑定手机验证码验证
 * @param params
 */
export function shopsSecurityExchangeBindNew(params) {
  return request({
    url: `/seller/shops/security/exchange-bind/${params.mobile}`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 获取当前手机号
 * @param
 */
export function shopsSecurityGetMobile() {
  return request({
    url: '/seller/shops/security/getMobile',
    method: 'get',
    loading: false
  })
}

/**
 * 判断密码是否正确
 * @param params
 */
export function shopsSecurityJudgePassword(params) {
  return request({
    url: '/seller/shops/security/judgePassword',
    method: 'get',
    loading: false,
    params
  })
}
